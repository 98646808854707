// open source
import gouroborosLogo from '../assets/gouroboros-logo.png';
import nviewLogo from '../assets/nview-screenshot.png';
import adderLogo from '../assets/adder-logo.png';
import dingoLogo from '../assets/dingo-logo.png';
import txsubmitLogo from '../assets/txsubmit-logo.png';
import bluefinLogo from '../assets/bluefin-logo.png';
import bursaLogo from '../assets/bursa-logo.png';
import nodeapiLogo from '../assets/nodeapi-logo.png';
import txtopScreenshot from '../assets/txtop-screenshot.png';
import dockerLogo from '../assets/docker-logo.png';

// customer
import butaneLogo from '../assets/butane-logo.svg';
import cardanofoundationLogo from '../assets/cardanofoundation-logo.png';
import cetfLogo from '../assets/cetf-logo.png';
import maestroLogo from '../assets/maestro-logo.png';
import orcfaxLogo from '../assets/orcfax-logo.svg';
import silktoadLogo from '../assets/silktoad-logo.png';
import skyprotocolLogo from '../assets/skyprotocol-logo.svg';
import tosidropLogo from '../assets/tosidrop-logo.png';
import txpipeLogo from '../assets/txpipe-logo.svg';

// industry
import armadaLogo from '../assets/armada-alliance-logo.png';
import cbiaLogo from '../assets/cbia-logo.png';
import pragmaLogo from '../assets/pragma-logo.svg';
import utxorpcLogo from '../assets/utxorpc-logo.svg';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faGithub,
  faDiscord,
  faLinkedin,
  faMedium,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCode,
  faIndustry,
  faList,
  faPhotoFilm,
  faUser,
  faUsers,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';

export interface NavLink {
  name: string;
  url?: string;
  icon?: IconProp;
  desc?: string;
  subMenu?: NavLink[];
}

export const openSourceLinks: NavLink = {
  name: 'Open Source',
  url: '/projects-open-source',
  icon: faCode,
};

export const serviceLinks: NavLink = {
  name: 'Services',
  url: '/services',
  icon: faList,
};

// used in the homepage
export const mainLinks: NavLink = {
  name: 'Projects',
  subMenu: [
    {
      name: 'Open Source Projects',
      icon: faGithub,
      desc: "We're on a mission to create a thriving Cardano community by developing open source tools and libraries. We firmly believe that open source software is the catalyst for community strength and vibrancy. Explore our projects and join us in shaping the future of Cardano.",
      url: '/projects-open-source',
    },
    {
      name: 'Customer Projects',
      icon: faUser,
      desc: 'Discover our customer success stories. Our approach to customer projects is rooted in our extensive experience in building global-scale operations. We bring this world-class expertise to every project, ensuring exceptional outcomes for our valued clients.',
      url: '/projects-customer',
    },
    {
      name: 'Industry Projects',
      icon: faIndustry,
      desc: 'Driving innovation through collaboration. We thrive on partnerships with like-minded teams and projects, united by a shared vision. Together, we propel blockchain technology forward, advancing toward a global financial revolution.',
      url: '/projects-industry',
    },
  ],
};

export const companyLinks: NavLink = {
  name: 'Company',
  subMenu: [
    {
      name: 'About Us',
      url: '/about',
      icon: faUsers,
    },
    {
      name: 'Media Kit',
      url: '/mediakit',
      icon: faPhotoFilm,
    },
    {
      name: 'Customers',
      icon: faUser,
      desc: 'Discover our customer success stories. Our approach to customer projects is rooted in our extensive experience in building global-scale operations. We bring this world-class expertise to every project, ensuring exceptional outcomes for our valued clients.',
      url: '/projects-customer',
    },
    {
      name: 'Partnerships',
      icon: faIndustry,
      desc: 'Driving innovation through collaboration. We thrive on partnerships with like-minded teams and projects, united by a shared vision. Together, we propel blockchain technology forward, advancing toward a global financial revolution.',
      url: '/projects-industry',
    },
    {
      name: 'Careers',
      url: '/jobs',
      icon: faBriefcase,
    },
  ],
};

export const contactLinks: NavLink = {
  name: 'Contact',
  subMenu: [
    {
      name: 'Discuss on Discord',
      url: 'https://discord.gg/5fPRZnX4qW',
      icon: faDiscord,
    },
    {
      name: 'Code with Us on GitHub',
      url: 'https://github.com/blinklabs-io',
      icon: faGithub,
    },
    {
      name: 'Follow Us on LinkedIn',
      url: 'https://www.linkedin.com/company/blink-labs-io/',
      icon: faLinkedin,
    },
    {
      name: 'Follow Us on Medium',
      url: 'https://medium.com/@blinklabs_io',
      icon: faMedium,
    },
    {
      name: 'Follow Us on Twitter',
      url: 'https://twitter.com/blinklabs_io',
      icon: faTwitter,
    },
    {
      name: 'Follow Us on YouTube',
      url: 'https://www.youtube.com/@blinklabs_io',
      icon: faYoutube,
    },
  ],
};

// populates the header navigation links
export const navLinks: NavLink[] = [
  // blogLinks,
  // downloadLinks,
  // mainLinks,
  openSourceLinks,
  serviceLinks,
  companyLinks,
  contactLinks,
];

export interface ProjectData {
  name: string;
  repo: string;
  isOpenSource: boolean;
  isDocker?: boolean;
  logos: {
    logo: string | null;
  };
  descs: {
    descShort: string;
  };
  urls: Array<{ name: string; link: string }>;
  downloads?: Array<{
    name: string;
    url: string;
    osMatch: boolean;
    cpuMatch: boolean;
    version: string;
    osName: string;
  }>;
  downloadsCache?: string;
  version?: string;
  hasCpuMatch?: boolean;
  hasOsMatch?: boolean;
}

export interface ProjectDataGroup {
  heading: string;
  desc?: string;
  projects: ProjectData[];
}

export interface ProjectDescs {
  descShort: string;
}

export interface ProjectLogos {
  logo?: string;
  logoCompact?: string;
}

export interface ProjectURL {
  name: string;
  link?: string;
}

export const openSourceProjectData: ProjectDataGroup = {
  heading: 'Open Source Projects',
  desc: "Our mission is clear: to build and deploy software solutions that set new industry standards. While we specialize in the Go language, our aim is to simplify the lives of all developers, offering them the tools and resources they need to excel in a rapidly evolving tech landscape. This page will attempt to detect which file type your system needs. Other OS systems are available in the dropdown menu and you can find older releases in the application's release page on Github",
  projects: [
    {
      // gOuroboros
      name: 'gOuroboros',
      repo: 'gOuroboros',
      isOpenSource: true,
      logos: {
        logo: gouroborosLogo,
      },
      descs: {
        descShort:
          'gOuroboros is a Go implementation of the Cardano Ouroboros network protocol and its mini-protocols, which allows for communication on the Cardano blockchain using its wire protocol. gOuroboros is the primary building block for creating tools and applications for Cardano in Go.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // Adder
      name: 'Adder',
      repo: 'Adder',
      isOpenSource: true,
      logos: {
        logo: adderLogo,
      },
      descs: {
        descShort:
          'Adder is a tool for tailing the Cardano blockchain and emitting events for each block and transaction seen, based on user configurable filters. These events can be processed in other event-based toolchains to produce rich and reactive applications for Cardano.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // dingo
      name: 'Dingo',
      repo: 'dingo',
      isOpenSource: true,
      logos: {
        logo: dingoLogo,
      },
      descs: {
        descShort:
          'A Cardano blockchain data node written in Go which actively participates in network communications on the Cardano blockchain using the Ouroboros Network Node-to-Node family of mini-protocols. This project was funded in Project Catalyst Fund 12.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // nview
      name: 'nview',
      repo: 'nview',
      isOpenSource: true,
      logos: {
        logo: nviewLogo,
      },
      descs: {
        descShort:
          'nview is a local monitoring tool for a Cardano Node (cardano-node) meant to complement remote monitoring tools by providing a local view of a running node from the command line. It is a TUI (terminal user interface) designed to fit most screens.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // tx-submit-api
      name: 'Tx Submit API',
      repo: 'tx-submit-api',
      isOpenSource: true,
      logos: {
        logo: txsubmitLogo,
      },
      descs: {
        descShort:
          'A Cardano transaction submit API service written in Go which takes CBOR encoded transaction payloads over HTTP and converts it to the Ouroboros Network LocalTxSubmission mini-protocol via gOuroboros. This project was funded in Project Catalyst Fund 9.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // bluefin
      name: 'Bluefin',
      repo: 'bluefin',
      isOpenSource: true,
      logos: {
        logo: bluefinLogo,
      },
      descs: {
        descShort:
          'A standalone Fortuna (TUNA) miner, written in Go, which syncs the chain, mines for TUNA, and submits transactions to remote nodes without any other infrastructure.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // bursa
      name: 'Bursa',
      repo: 'bursa',
      isOpenSource: true,
      logos: {
        logo: bursaLogo,
      },
      descs: {
        descShort:
          'A programmatic Cardano wallet, written in Go, which exposes an API, CLI, and library interface, allowing developers to easily integrate wallet functionality.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // cardano-node-api
      name: 'Cardano Node API',
      repo: 'cardano-node-api',
      isOpenSource: true,
      logos: {
        logo: nodeapiLogo,
      },
      descs: {
        descShort:
          'Cardano Node API is a service written in Go which communicates with a Cardano Node over its private interface and provides a featureful set of HTTP APIs built on collaborative standards.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // txtop
      name: 'TxTop',
      repo: 'txtop',
      isOpenSource: true,
      logos: {
        logo: txtopScreenshot,
      },
      descs: {
        descShort:
          "TxTop is a local monitoring tool for a Cardano Node (cardano-node) meant to provide a local view of a running node's mempool transactions with a simple icon legend for at-a-glance transaction categorization.",
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // tx-submit-api-mirror
      name: 'Tx Submit API Mirror',
      repo: 'tx-submit-api-mirror',
      isOpenSource: true,
      logos: {
        logo: null,
      },
      descs: {
        descShort:
          'Simple cardano-submit-api look-alike which mirrors requests asynchronously to configured backends.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // cdnsd
      name: 'cDNSd',
      repo: 'cdnsd',
      isOpenSource: true,
      logos: {
        logo: null,
      },
      descs: {
        descShort:
          'Cardano DNS daemon for serving blockchain-based domain names over the DNS protocol.',
      },
      urls: [],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
    {
      // docker images
      name: 'Docker Images',
      repo: 'docker-images',
      isOpenSource: true,
      isDocker: true,
      logos: {
        logo: dockerLogo,
      },
      descs: {
        descShort:
          'Blinks Labs maintains public container images for Cardano CLI, DB Sync, Node, and Wallet, and Mithril Client and Signer. Built on Debian Linux and compiled for AMD64 and ARM64 architectures.',
      },
      urls: [
        {
          name: 'Docker Hub',
          link: 'https://hub.docker.com/u/blinklabs',
        },
        {
          name: 'GitHub Packages',
          link: 'https://github.com/orgs/blinklabs-io/packages',
        },
      ],
      downloads: [],
      downloadsCache: '',
      version: '',
      hasCpuMatch: false,
      hasOsMatch: false,
    },
  ],
};

export const customerProjectData: ProjectDataGroup = {
  heading: 'Customer Projects',
  desc: "Discover how our decades of experience and unwavering commitment to the Cardano community can elevate your projects to new heights. Join us, and let's build the future together.",
  projects: [
    {
      // Cardano Foundation
      name: 'Cardano Foundation',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: cardanofoundationLogo,
      },
      descs: {
        descShort:
          'The Cardano Foundation is an independent, Swiss-based not-for-profit organization tasked with advancing Cardano as a public infrastructure across a wide range of industries.',
      },
      urls: [
        {
          name: 'Cardano Foundation',
          link: 'https://cardanofoundation.org',
        },
      ],
    },
    {
      // Butane
      name: 'Butane',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: butaneLogo,
      },
      descs: {
        descShort:
          'Butane is a fast, powerful, and innovative decentralised synthetics platform built on the Cardano blockchain.',
      },
      urls: [
        {
          name: 'Butane',
          link: 'https://butane.dev',
        },
      ],
    },
    {
      // TxPipe
      name: 'TxPipe',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: txpipeLogo,
      },
      descs: {
        descShort:
          'TxPipe is the premiere open source Rust development shop on Cardano.',
      },
      urls: [
        {
          name: 'TxPipe',
          link: 'https://txpipe.io',
        },
      ],
    },
    {
      // Orcfax
      name: 'Orcfax',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: orcfaxLogo,
      },
      descs: {
        descShort:
          'Orcfax provides decentralized oracle feeds for Cardano smart contracts.',
      },
      urls: [
        {
          name: 'Orcfax',
          link: 'https://orcfax.io',
        },
      ],
    },
    {
      // Maestro
      name: 'Maestro',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: maestroLogo,
      },
      descs: {
        descShort:
          'Maestro has a mission to acclerate blockchain adoption by lowering barriers to entry into Cardano development.',
      },
      urls: [
        {
          name: 'Maestro',
          link: 'https://gomaestro.org',
        },
      ],
    },
    {
      // Sky Protocol
      name: 'Sky Protocol',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: skyprotocolLogo,
      },
      descs: {
        descShort:
          'Sky Protocol offers a universal layer 2 solution that maximizes scalability and interoperability, powered by Cardano.',
      },
      urls: [
        {
          name: 'Sky Protocol',
          link: 'https://skyprotocol.org',
        },
      ],
    },
    {
      // The Silk Toad
      name: 'THE SILK TOAD',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: silktoadLogo,
      },
      descs: {
        descShort:
          'THE SILK TOAD is a marketplace for and by the Disco Solaris NFT community.',
      },
      urls: [
        {
          name: 'The Silk Toad',
          link: 'https://silktoad.io',
        },
      ],
    },
    {
      // TosiDrop
      name: 'TosiDrop',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: tosidropLogo,
      },
      descs: {
        descShort:
          'TosiDrop is a token distribution platform for Cardano and Ergo blockchains.',
      },
      urls: [
        {
          name: 'TosiDrop',
          link: 'https://tosidrop.io',
        },
      ],
    },
    {
      // CardanoETF
      name: 'Cardano ETF',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: cetfLogo,
      },
      descs: {
        descShort:
          'CETF is a Decentralized Autonomous Organization which invests in Cardano native tokens.',
      },
      urls: [
        {
          name: 'Cardano ETF',
          link: 'https://cardanoetf.io',
        },
      ],
    },
  ],
};

export const industryProjectData: ProjectDataGroup = {
  heading: 'Industry Projects',
  desc: 'At Blink Labs, we are committed to actively contributing to the Cardano blockchain and its growth and development. We are proud participants in a variety of industry projects that shape the future of decentralized technology. Our engagement extends to collaborations, partnerships, and contributions to projects that drive innovation, security, and accessibility within the blockchain ecosystem. Explore our involvement in these industry initiatives, as we work together to build a decentralized future that empowers individuals and organizations worldwide.',
  projects: [
    {
      // PRAGMA
      name: 'PRAGMA',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: pragmaLogo,
      },
      descs: {
        descShort:
          'PRAGMA is a member-based, not-for-profit open-source association for blockchain software projects. Blink Labs is a founding member of PRAGMA.',
      },
      urls: [
        {
          name: 'PRAGMA Website',
          link: 'https://pragma.builders',
        },
      ],
    },
    {
      // CBIA
      name: 'Cardano Blockchain Infrastructure Alliance',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: cbiaLogo,
      },
      descs: {
        descShort:
          'The Cardano Blockchain Infrastructure Alliance is a developer organization focused on solving the biggest challenges faced by builders on Cardano. Blink Labs is a founding member of the CBIA.',
      },
      urls: [
        {
          name: 'CBIA Website',
          link: 'https://cbia.io',
        },
      ],
    },
    {
      // Armada Alliance
      name: 'Armada Alliance',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: armadaLogo,
      },
      descs: {
        descShort:
          'The Armada alliance was formed in hope to build a sustainable community of decentralized, low-cost, and energy-efficient stake pool operations on the Cardano blockchain. Blink Labs builds and supports all of its software on ARM.',
      },
      urls: [
        {
          name: 'Armada Alliance',
          link: 'https://armada-alliance.com',
        },
      ],
    },
    {
      // UTxORPC
      name: 'UTxO RPC',
      repo: '',
      isOpenSource: false,
      logos: {
        logo: utxorpcLogo,
      },
      descs: {
        descShort:
          'UTxO RPC (u5c for short) is an interface tailored for interactions with UTxO-based blockchains, prioritizing performance and developer experience. Blink Labs is the owner of the Go SDK for UTxO RPC.',
      },
      urls: [
        {
          name: 'UTxO RPC',
          link: 'https://utxorpc.org',
        },
      ],
    },
  ],
};

export interface DownloadURL {
  name: string;
  url: string;
  osMatch: boolean;
  cpuMatch: boolean;
  osName?: string;
  version?: string;
}

export interface DownloadRepoData {
  name: string;
  userName: string;
  repo: string;
  version?: string;
  desc?: string;
  hasOsMatch?: boolean;
  hasCpuMatch?: boolean;
  downloads?: DownloadURL[];
  downloadsCache?: string;
}

export interface DownloadsData {
  repos: DownloadRepoData[];
}

export const downloadsData: DownloadsData = {
  repos: [
    {
      name: 'nview',
      userName: 'blinklabs-io',
      repo: 'nview',
      desc: 'nview is a local monitoring tool for a Cardano Node (cardano-node) meant to complement remote monitoring tools by providing a local view of a running node from the command line. It is a TUI (terminal user interface) designed to fit most screens.',
    },
    {
      name: 'Adder',
      userName: 'blinklabs-io',
      repo: 'adder',
      desc: 'Adder is a CLI tool for tailing the Cardano blockchain and emitting events for each block and transaction seen, based on user configurable filters. These events can be processed in other event-based toolchains to produce rich and reactive applications for Cardano.',
    },
    {
      name: 'TxTop',
      userName: 'blinklabs-io',
      repo: 'txtop',
      desc: "TxTop is a local monitoring tool for a Cardano Node (cardano-node) meant to provide a local view of a running node's mempool transactions with a simple icon legend for at-a-glance transaction categorization.",
    },
    {
      name: 'Bluefin',
      userName: 'blinklabs-io',
      repo: 'bluefin',
      desc: 'Bluefin is a Fortuna (TUNA) Proof of Work miner written in Go.',
    },
    {
      name: 'Tx Submit API',
      userName: 'blinklabs-io',
      repo: 'tx-submit-api',
      desc: 'A Cardano transaction submit API service written in Go which takes encoded transaction payloads over HTTP and converts it to the Ouroboros Network LocalTxSubmission mini-protocol via gOuroboros.',
    },
    {
      name: 'Dingo',
      userName: 'blinklabs-io',
      repo: 'dingo',
      desc: 'A Cardano blockchain data node written in Go which actively participates in network communications on the Cardano blockchain using the Ouroboros Network Node-to-Node family of mini-protocols.',
    },
    {
      name: 'Bursa',
      userName: 'blinklabs-io',
      repo: 'bursa',
      desc: 'Bursa is a programmatic Cardano wallet. It is an API, CLI (command line interface) tool, and library written in Go.',
    },
    {
      name: 'Cardano Node API',
      userName: 'blinklabs-io',
      repo: 'cardano-node-api',
      desc: 'A multi-protocol HTTP API for interfacing with a local Cardano Node and exposing Ouroboros mini-protocol data.',
    },
    {
      name: 'Tx Submit API Mirror',
      userName: 'blinklabs-io',
      repo: 'tx-submit-api-mirror',
      desc: 'Simple cardano-submit-api look-alike which mirrors requests asynchronously to configured backends.',
    },
  ],
};

export interface ContactData {
  image?: string;
  name: string;
  desc?: string;
  title?: string;
  links: ContactURLs;
}

export interface ContactURLs {
  github?: string;
  twitter?: string;
  linkedin?: string;
}

export const contactData: ContactData[] = [
  {
    image: 'https://avatars.githubusercontent.com/u/380021',
    name: 'Chris',
    links: {
      github: 'https://github.com/wolf31o2',
      linkedin: 'https://linkedin.com/in/wolf31o2',
      twitter: 'https://twitter.com/wolf31o2',
    },
    title: 'CEO & Co-Founder',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/825875',
    name: 'Aurora',
    links: {
      github: 'https://github.com/agaffney',
      linkedin: 'https://linkedin.com/in/agaffney/',
      twitter: 'https://twitter.com/agaffney1548',
    },
    title: 'Lead Developer & Co-Founder',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/136919976',
    name: 'Christina',
    links: {
      github: 'https://github.com/musik-c',
      linkedin: 'https://www.linkedin.com/in/christina-gianelloni/',
      twitter: 'https://twitter.com/InASingleWord',
    },
    title: 'COO & Community',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/20187617',
    name: 'Ales',
    links: {
      github: 'https://github.com/verbotenj',
    },
    title: 'Backend Developer & Mobile',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/35861207',
    name: 'Marty',
    links: {
      github: 'https://github.com/overcookedpanda',
      twitter: 'https://twitter.com/OvercookedPanda',
    },
    title: 'Infrastructure',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/107831698',
    name: 'Brian',
    links: {
      github: 'https://github.com/JaeBrian',
    },
    title: 'Frontend Developer',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/83303972',
    name: 'Rich',
    links: {
      github: 'https://github.com/EarnCoinPool',
      twitter: 'https://twitter.com/earncoinpool',
    },
    title: 'Technical Writer',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/197835958',
    name: 'Jenita',
    links: {
      github: 'https://github.com/jkawan',
    },
    title: 'Developer',
  },
  {
    image: 'https://avatars.githubusercontent.com/u/145306795',
    name: 'Akhil',
    links: {
      github: 'https://github.com/arepala-uml',
    },
    title: 'Developer',
  },
];

export interface JobData {
  id: string;
  title: string;
  desc: string;
  longDesc?: string;
  active: boolean;
  url: string;
  linkTitle?: string;
}

export interface JobsDataGroup {
  heading: string;
  desc?: string;
  jobs: JobData[];
}

export const jobsDataGroup = {
  heading: 'Join Us',
  desc: 'We are always looking for talented individuals to join our team. If you are passionate about blockchain technology and want to work with a team of like-minded individuals, we would love to hear from you. Blink Labs is a fully remote company, incorporated in WY, USA. We offer a collaborative and inclusive environment with flexible hours.',
  jobs: [
    {
      id: 'core-developer-go',
      title: 'Core Developer (Golang)',
      desc: 'Gophers unite! We are looking for a developer to join our Core team, developing primarily in Go.',
      longDesc: 'The Core team at Blink Labs is responsible for our collection of blockchain-based backend services, libraries, and tools. The ideal candidate will be experienced in Go and comfortable building highly concurrent and performant data services applications in a containerized environment. Familiarity with blockchains, cryptography, distributed databases, and zero trust security is a plus. Primary duties involve the development of open source software within Blink Labs and partner ecosystems. This position is part time and fully remote, US based.',
      active: true,
      url: 'https://www.google.com',
    },
    {
      id: 'infrastructure-engineer-k8s',
      title: 'Infrastructure Engineer (Kubernetes)',
      desc: 'Do you dream in YAML? Our Ops team is looking for a cloud native with expertise in Kubernetes.',
      longDesc: 'Blink Labs is looking to expand our team of cloud native experts to continue building and scaling our containerized solutions. The ideal candidate will be experienced with creating and maintaining operators, charts, images, and other necessities of the cloud native world. Familiarity with highly available data processing systems is greatly desired. On call activities are a requirement, but compensated separately. This position is part time and fully remote, US based.',
      active: true,
      url: 'https://www.google.com',
    },
  ],
};
